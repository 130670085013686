/* Recipe */
.recipe {
  break-inside: avoid;
  background-color: var(--clr-01dp);
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.recipe-icons {
  display: flex;
  gap: 0.75rem;
  justify-content: center;
  align-items: flex-start;
}

.recipe-icons i {
  font-size: 1.2em;
  scale: 1;
  cursor: pointer;
}

.recipe-icons i:hover {
  /* scale: 1.3; */
  color: var(--clr-accent);
}

.recipe-header {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.recipe-header button {
  all: unset;
}

.recipe-body {
  display: flex;
  flex-direction: column;
  gap: 1ch;
}

.recipe-body a {
  color: var(--clr-accent);
  text-decoration: underline;
}

.recipe-info {
  display: flex;
  justify-content: space-between;
}

.recipe-time {
  display: flex;
  align-items: center;
  gap: .2rem;
}

.recipe-body img {
  border-radius: 5px;
}