/* NavBar */

nav {
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: fixed;
  padding: 1rem;
  gap: 1rem;
  background-color: var(--clr-bg);
  z-index: 999;
}

nav ul {
  padding: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

nav ul li {
  display: flex;
  gap: 1rem;
}

nav .logo a {
  font-weight: bold;
  white-space: nowrap;
}

/* Nav Login Dropdown */

.profile {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.login-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 30px;
  overflow: hidden;
  transition: height 300ms ease;
}

.login-dropdown:hover {
  height: 250px;
}

.nav-dropdown {
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: var(--clr-01dp);
  padding: 1rem;
  border-radius: 10px;
  gap: 0.25rem;
}

.nav-dropdown a {
  display: flex;
  gap: 1ch;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
}

.nav-dropdown a:hover {
  background-color: var(--clr-06dp);
}

@media (min-width: 1200px) {

}

@media (min-width: 900px) {

}

@media (min-width: 768px) {
  nav {
    background-color: transparent;
  }
}

@media (min-width: 480px) {
  nav {
    flex-direction: row;
    align-items: flex-start;
  }
}
