.add-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.add-form div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.add-form > div {
  flex-direction: column;
}

.add-form .multi-container,
.add-form .multi-items-container {
  display: flex;
  flex-direction: column;
}

.add-form textarea,
.add-form button {
  background-color: var(--clr-01dp);
  color: var(--clr-fg);
  border: none;
  border-radius: 5px;
  outline: none;
  width: 100%;
  padding: 0.5rem 1rem;
}

.add-form textarea {
  resize: none;
}

.add-form button {
  all: unset;
  cursor: pointer;
  background-color: var(--clr-accent);
  border-radius: 5px;
  padding: 0.5rem 1rem;
  scale: 1;
  transition: scale 200ms ease;
}

.add-form button {
  width: 100%;
  padding: 0.5rem 0;
}

.multi-container button {
  background-color: var(--clr-bg);
  border: 2px solid var(--clr-accent);
}

@media (min-width: 786px) {
  .add-form > div {
    flex-direction: row;
  }
}