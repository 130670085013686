.tooltip-container {
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.tooltip {
  display: none;
  z-index: 99;
  color: var(--clr-fg);
  width: max-content;
  font-size: 11px;
  padding: 5px 10px;
  border-radius: 3px;
  text-align: center;
  background: var(--clr-08dp);
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  top: 32px;
  pointer-events: none;
  font-size: 1rem;
}

.tooltip:before,
.tooltip:after {
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--clr-08dp);
  position: absolute;
  top: -10px;
  left: 43%;
}

.tooltip-container:hover .tooltip {
  display: block;
}
