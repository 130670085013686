.catalog-container,
.personal-container {
  display: grid;
  grid-template-columns: 4fr 1fr;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;
}

.personal-container {
  grid-template-columns: 1fr;
}

.catalog-filters h4 {
  font-weight: 600;
}

.catalog-items-container {
  max-height: calc(100vh - 20rem);
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 1rem;
}

.catalog h2 b, .comment-container h2 b {
  color: var(--clr-accent);
}

.catalog-items {
  text-align: left;
  columns: 3;
  column-gap: 1rem;
}

.catalog,
.catalog-filters {
  display: flex;
  gap: 2rem;
  border-radius: 20px;
  padding: 2rem;
  margin: 1rem auto;
  width: fit-content;
  background-color: var(--clr-00dp);
  width: 100%;
}

.catalog {
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
}

.catalog-filters {
  flex-direction: column;
}

.selected-filters button {
  all: unset;
  cursor: pointer;
  background-color: var(--clr-bg);
  border: 2px solid var(--clr-accent);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 200px;
  padding: 0.5rem 1rem;
}

.catalog-filters button p {
  width: 100%;
}

.catalog-filters .filter {
  border: 2px solid var(--clr-01dp);
  background-color: var(--clr-01dp);
  text-align: left;
  margin: auto;
  margin-bottom: 1rem;
  overflow: hidden;
}

.catalog-filters .filter p {
  white-space: nowrap;
  overflow: hidden;
  max-width: 180px;
  text-overflow: ellipsis;
}

.catalog-filters .filter .filter-content {
  opacity: 0.5;
  font-size: 0.8em;
}

.catalog-filters .apply-btn {
  background-color: var(--clr-accent);
  scale: 1;
  transition: scale 200ms ease;
  min-width: 150px;
}

.catalog-filters .apply-btn:hover {
  scale: 0.95;
}

.catalog-settings > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-dropdown {
  cursor: pointer;
  background-color: var(--clr-bg);
  border: 2px solid var(--clr-accent);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 236px;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  height: 45px;
  overflow: hidden;
  transition: height 250ms ease;
  margin: 0 auto;
}

.dropdown-rotate {
  font-size: 1.2em;
  transition: all 250ms;
}

.filter-dropdown.open .dropdown-rotate {
  rotate: 45deg;
}

.filter-dropdown.open {
  height: 205px;
}

.dropdown-visible {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dropdown-visible p {
  width: 100%;
}

.dropdown_content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  padding: 0.75rem 0;
}

.catalog-filter input,
.catalog-filter select,
.catalog-filter option,
.login-form input {
  background-color: var(--clr-01dp);
  color: var(--clr-fg);
  border: none;
  border-radius: 5px;
  outline: none;
  width: 100%;
}

.catalog-filter input,
.catalog-filter select,
.login-form input {
  padding: 0.5rem 1rem;
}

.dropdown_content > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.dropdown_content button,
.login-form button,
nav button,
.add-input button {
  all: unset;
  cursor: pointer;
  background-color: var(--clr-accent);
  border-radius: 5px;
  padding: 0.5rem 1rem;
  scale: 1;
  transition: scale 200ms ease;
}

.add-input button {
  width: 100%;
  padding: 0.5rem 0;
}

.dropdown_content button:hover,
.login-form button:hover,
nav button:hover,
.add-input button:hover {
  scale: 0.95;
}

.dropdown_content p {
  display: block !important;
}

@media (max-width: 1200px) {
  .catalog-items {
    columns: 2;
  }
}

@media (max-width: 900px) {
  .catalog-items {
    columns: 1;
  }
}

@media (max-width: 768px) {
  .catalog-container {
    display: flex;
    flex-direction: column-reverse;
    gap: 0;
  }
}