@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;700&display=swap");

:root {
  --clr-bg: #121212;
  --clr-fg: #fff;
  --clr-bg-light: #323232;
  --clr-accent: #ff6723;

  --clr-00dp: hsl(0, 0%, 8%);
  --clr-01dp: hsl(0, 0%, 11%);
  --clr-02dp: hsl(0, 0%, 13%);
  --clr-03dp: hsl(0, 0%, 14%);
  --clr-04dp: hsl(0, 0%, 15%);
  --clr-06dp: hsl(0, 0%, 17%);
  --clr-08dp: hsl(0, 0%, 18%);
  --clr-12dp: hsl(0, 0%, 20%);
  --clr-16dp: hsl(0, 0%, 21%);
  --clr-24dp: hsl(0, 0%, 22%);

  --clr-bg-transparent: rgba(0, 0, 0, 0.2);
  --clr-bg-transparent2: hsla(0, 0%, 7%, 60%);

  --grid-columns: 8;
  --grid-gap: 1rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html,
body {
  height: 100%;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

#root,
#__next {
  isolation: isolate;
}

/* CSS */

*::selection {
  background: var(--clr-fg);
  color: var(--clr-bg);
}

a {
  text-decoration: none;
  color: var(--clr-fg);
}

body {
  color: var(--clr-fg);
  background-color: var(--clr-bg);
  font-family: "Heebo", sans-serif;
  text-align: center;
}

#root {
  height: 100%;
}

.App {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

/* Page */

.page {
  padding-top: 7rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  height: 100%;
}

.info b {
  font-weight: normal;
  color: var(--clr-accent);
}

/* Login / Signup */

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  border-radius: 20px;
  background-color: var(--clr-00dp);
  margin: auto;
  width: 100%;
  max-width: 350px;
}

.error {
  color: red;
}

.login-form .signup {
  color: var(--clr-accent);
}

.login-form .signup:hover {
  text-decoration: underline;
}

.search-input {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--clr-01dp);
  border-radius: 10px;
  padding: 0.2rem 0 0.2rem 0.75rem;
  margin-top: 0.5rem;
}

/* Loading Spinner */

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid var(--clr-accent);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

/* Buttons */

.btn-primary {
  all: unset;
  cursor: pointer;
  background-color: var(--clr-accent);
  border-radius: 5px;
  padding: 0.5rem 1rem;
  scale: 1;
  transition: scale 200ms ease;
}

.btn-secondary {
  all: unset;
  cursor: pointer;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  scale: 1;
  transition: scale 200ms ease;
  background-color: var(--clr-bg);
  border: 2px solid var(--clr-accent);
}

.btn-primary:hover, .btn-secondary:hover {
  scale: 0.95;
}

/* Recipe Page */

.recipe-container {
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;
}

.recipe-container .info {
  background-color: var(--clr-00dp);
  padding: 2rem;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.recipe-content {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  text-align: left;
  margin-bottom: 4rem;
  background-color: var(--clr-00dp);
  padding: 2rem;
  border-radius: 20px;
}

.recipe-content img {
  max-height: 380px;
  margin: 0 auto;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.directions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.comments {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: .5rem;
  text-align: left;
}

.add-comment input {
  background-color: var(--clr-01dp);
  color: var(--clr-fg);
  border: none;
  border-radius: 5px;
  outline: none;
  width: 100%;
  padding: 0.5rem 1rem;
}

.add-comment {
  display: flex;
  gap: .5rem;
}

.comment-container {
  margin: 0 auto;
  max-width: 1440px;
  padding: 1rem 0;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  .recipe-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .recipe-container .info {
    flex-direction: row;
  }
}

@media (min-width: 480px) {
  .page {
    padding-top: 5rem;
  }
}
